
import HourglassLoader from "@/components/loader/HourglassLoader.vue";

import { defineComponent } from "vue";

import CardWithdrawal from "@/views/withdrawals/CardWithdrawal.vue";
import CoinWithdrawal from "@/views/withdrawals/CoinWithdrawal.vue";

export default defineComponent({
  name: "Withdrawals",
  components: {
    CardWithdrawal,
    CoinWithdrawal,
  },
  setup() {
    return {};
  },
});
