<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">System Withdrawals</h2>
          <p class="mb-0">Below are the users withdrawals</p>
        </div>

        <!-- <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
        </div>-->
      </div>

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="card-tab"
            data-bs-toggle="tab"
            data-bs-target="#card"
            type="button"
            role="tab"
            aria-controls="card"
            aria-selected="true"
          >Fiat</button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="coin-tab"
            data-bs-toggle="tab"
            data-bs-target="#coin"
            type="button"
            role="tab"
            aria-controls="coin"
            aria-selected="false"
          >Coins</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="card" role="tabpanel" aria-labelledby="card-tab">
          <CardWithdrawal />
        </div>
        <div class="tab-pane fade" id="coin" role="tabpanel" aria-labelledby="coin-tab">
          <!-- <div class="d-flex justify-content-center align-items-center h-25">
            <h1 class="my-5">Coming soon</h1>
          </div>-->
          <CoinWithdrawal />
        </div>
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";

import { defineComponent } from "vue";

import CardWithdrawal from "@/views/withdrawals/CardWithdrawal.vue";
import CoinWithdrawal from "@/views/withdrawals/CoinWithdrawal.vue";

export default defineComponent({
  name: "Withdrawals",
  components: {
    CardWithdrawal,
    CoinWithdrawal,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
